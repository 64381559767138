<template>
  <div class="wrapper">
    <div class="head_card">
      <p class="nums">开通药企<span class="n">{{enterpriseNum}}</span>家</p>
      <p class="nums_tips ds_flex al_center ju_center" v-if="percent">环比{{percent}}<span class="icon" :class="{icon_up: percent && percent.indexOf('-') == -1, icon_down: percent && percent.indexOf('-') > -1 && percent.indexOf('--') == -1}"></span></p>
    </div>
    <div class="big_title">药企清单</div>
    <div class="head_tab ds_flex al_center">
      <div class="num">1</div>
      <div class="name">药企名称</div>
      <div class="date">添加时间</div>
    </div>
    <div class="list">
      <div class="item ds_flex al_center" v-for="(item, index) in enterpriseData.enterpriseStatistics" :key="index">
        <div class="num">{{index + 1}}.</div>
        <div class="name">{{item.enterpriseName}}</div>
        <div class="date">{{formatTime(item.createTime)}}</div>
      </div>
    </div>
  </div>
</template>
<script>
let self = null;
import { getDate, getMonthStartAndEnd, getWeekStartAndEnd, renderTime } from '@/utils/util';
export default {
  components: {
  },
  data() {
    return {
      activeNames: ['1'],
      time: null,
      enterpriseNum: this.$route.query.enterpriseNum,
      percent: this.$route.query.percent,
      enterpriseData: [],
      userInfo: JSON.parse(localStorage.getItem('userInfo'))
    }
  },
  created() {
    document.title = this.$route.query.title + '-' + this.$route.query.time + '开通药企';
    if (this.$route.query.time == '本周') {
      this.time = getDate('-', '', false);
    } else if (this.$route.query.time == '本月') {
      this.time = getDate('month', '', false);
    } else {
      if (this.$route.query.type == 1) {
        this.time = this.$route.query.weekDate;
      } else {
        this.time = this.$route.query.time;
      }
    }
    this.ykfStatisticData(this.time, this.$route.query.type);
    self = this;
  },
  methods: {
    formatTime(time) {
      return renderTime(time)
    },
    // 药康夫统计
    ykfStatisticData(date, type) {
      console.log('agentId', this.userInfo)
      let params = {}
      if (type == 1) {
        params = {
          beginDate: getWeekStartAndEnd(0, date)[0],
          endDate: getWeekStartAndEnd(0, date)[1],
          timePeriod: 'week'
        }
      } else {
        params = {
          beginDate: getMonthStartAndEnd(0, date)[0],
          endDate: getMonthStartAndEnd(0, date)[1],
          timePeriod: 'month'
        }
      }
      params.agentId = this.userInfo.user.agentId;
      this.$http('get', '/yaokangfu/system/enterpriseStatistic/getHistory', params).then(res => {
        if (res.code == 200) {
          this.enterpriseData = res.data;
        }
      })
    },
  }
}
</script>
<style lang="less" scoped>
.ds_flex {
  display: flex;
  display: -webkit-flex;
}
.ju_center {
  justify-content: center;
  -webkit-justify-content: center;
}
.al_center {
  align-items: center;
  -webkit-align-items: center;
}
.wrapper {
  background: #f8f8f8;
  min-height: 100vh;
}
.head_card {
  padding: 0.48rem 0;
  text-align: center;
  border-top: 0.1rem solid #f8f8f8;
  background: #fff;
  .nums {
    margin-bottom: 0.1rem;
    font-size: 0.24rem;
    color: #666666;
    .n {
      margin: 0 0.12rem 0 0.27rem;
      font-size: 0.6rem;
      color: #3780ff;
    }
  }
  .nums_tips {
    font-size: 0.26rem;
    color: #333333;
    .icon {
      display: inline-block;
      margin-left: 0.12rem;
      width: 0.16rem;
      height: 0.23rem;
    }
    .icon_up {
      background: url("../../assets/img/icon_rise_nor@2x.png") no-repeat center /
        100% 100%;
    }
    .icon_down {
      background: url("../../assets/img/icon_reduce_nor@2x.png") no-repeat
        center / 100% 100%;
    }
  }
}
.big_title {
  padding: 0.25rem 0.3rem;
  font-size: 0.32rem;
  font-weight: bold;
  color: #333333;
}
.head_tab {
  padding: 0.35rem;
  border-bottom: 1px solid #ededed;
  background: #fff;
  text-align: center;
  font-size: 0.3rem;
  font-weight: bold;
  color: #333333;
  .num {
    // flex: .2;
    // -webkit-flex: .2;
    visibility: hidden;
    width: 0.27rem;
  }
  .name {
    flex: 1;
    -webkit-flex: 1;
  }
  .date {
    flex: 1;
    -webkit-flex: 1;
    // flex: 2;
    // -webkit-flex: 2;
  }
}
.list {
  padding: 0 0.35rem;
  background: #fff;
  text-align: center;
  .item {
    padding: 0.35rem 0;
    color: #333333;
    font-size: 0.28rem;
    border-bottom: 1px solid #f2f2f2;
    .num {
      width: 0.27rem;
      // flex: .2;
      // -webkit-flex: .2;
    }
    .name {
      flex: 1;
      -webkit-flex: 1;
    }
    .date {
      flex: 1;
      -webkit-flex: 1;
      font-size: 0.24rem;
      color: #999999;
      // flex: 2;
      // -webkit-flex: 2;
    }
  }
  .item:last-child {
    border-bottom: none;
  }
}
</style>
